// extracted by mini-css-extract-plugin
export var Bold = "MentionsLegales-module--Bold--P3+RD";
export var ContactLink = "MentionsLegales-module--ContactLink--du+Re";
export var Contactlink = "MentionsLegales-module--Contactlink--fRcdm";
export var DevelopmentWrapper = "MentionsLegales-module--DevelopmentWrapper--OBAiB";
export var LegalMentionsWrapper = "MentionsLegales-module--LegalMentionsWrapper--0qpUa";
export var Link = "MentionsLegales-module--Link--Vqpc6";
export var NameValueWrapper = "MentionsLegales-module--NameValueWrapper--Q+2ae";
export var RowWrapper = "MentionsLegales-module--RowWrapper--FAldE";
export var Wrapper = "MentionsLegales-module--Wrapper--QuyoX";
export var link = "MentionsLegales-module--link--3RXLQ";